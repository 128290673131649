import { isServer } from './isServer'

export const ErrorHandler = (e): any[] => {
  let errors = []
  if (!!e && !!e?.response && !!e?.response?.data) {
    switch (e?.response?.status) {
      case 400: {
        const { errors: serverErrors } = e.response.data
        serverErrors.forEach(({ message }) => errors.push({
          statusCode: 400,
          error: {
            title: 'Datos incorrectos',
            message,
            id: 'NETWORK_ERROR_400',
          },
        }))
        break
      }
      case 401: {
        if (!isServer) {
          const { errors: serverErrors } = e.response.data
          serverErrors.forEach(({ message }) => errors.push({
            statusCode: 401,
            error: {
              title: 'Error de autenticación',
              message,
              id: 'NETWORK_ERROR_401',
            },
          }))
        }
        break
      }
      case 404: {
        errors = [{
          statusCode: 404,
          error: {
            message: 'No se encontró lo que buscaba.',
            title: 'No encontrado',
            id: 'NETWORK_ERROR_404',
          },
        }]
        break
      }
      case 403: {
        const { errors: serverErrors } = e.response.data
        errors = []
        serverErrors.forEach(({ message }) => errors.push({
          statusCode: 403,
          error: {
            title: 'Error de autorización',
            message,
            id: 'NETWORK_ERROR_403',
          },
        }))
        break
      }
      case 500: {
        errors = [{
          statusCode: 500,
          error: {
            message: 'Algo ha ocurrido mal, si esto persiste contacte a soporte.',
            title: 'Error del servidor',
            id: 'NETWORK_ERROR_500',
          },
        }]
        break
      }
      default:
        errors = [{
          statusCode: e?.response?.status,
          error: e.response.data,
          id: 'NETWORK_ERROR_000',
        }]
        break
    }
  } else {
    errors = [{
      statusCode: 500,
      error: {
        message: 'Verifique su conexion e intente de nuevo.',
        title: 'Error de conexion',
        id: 'NETWORK_ERROR_500',
      },
    }]
  }
  return errors
}
