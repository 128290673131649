import { LOAD_NOTIFICATIONS, NotificationsState, READ_ALL, READ_ONE, CHANGE_HAS_MORE, CHANGE_LOADING } from './types'

const INITIAL_STATE: NotificationsState = {
  notifications: [],
  loading: false,
  hasMore: true,
}

const reducer = (state = INITIAL_STATE, { type, payload }): NotificationsState => {
  switch (type) {
    case CHANGE_HAS_MORE: return { ...state, hasMore: payload }
    case CHANGE_LOADING: return { ...state, loading: payload }
    case LOAD_NOTIFICATIONS: return { ...state, notifications: payload }
    case READ_ALL: {
      const notifications = [...state.notifications].map((notification) => ({ ...notification, unread: false }))
      return { ...state, notifications }
    }
    case READ_ONE: {
      const notifications = [...state.notifications].map((notification) => (notification.id === payload ? ({ ...notification, unread: false }) : notification))
      return { ...state, notifications }
    }
    default:
      return state
  }
}

export default reducer
