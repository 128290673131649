import {
  CoursesState,
  LOAD_COURSES,
  LOAD_THEME,
  LOAD_QUESTIONS,
  FINISHED_COURSE,
  LOAD_NEXT_THEME,
  LOAD_RECOMMENDATION,
  LOAD_PAYU_DATA_PURCHASE_ALL,
} from './types'

const INITIAL_STATE: CoursesState = {
  courses: [],
  questions: [],
  theme: null,
  finished: null,
  nextTheme: '',
  recommendation: '',
  allCourseData: null,
}

const reducer = (state = INITIAL_STATE, { payload, type }) => {
  switch (type) {
    case LOAD_PAYU_DATA_PURCHASE_ALL:
      return { ...state, allCourseData: payload }
    case LOAD_COURSES:
      return { ...state, courses: payload }
    case LOAD_THEME:
      return { ...state, theme: payload }
    case LOAD_QUESTIONS:
      return { ...state, questions: payload }
    case FINISHED_COURSE:
      return { ...state, finished: payload }
    case LOAD_NEXT_THEME:
      return { ...state, nextTheme: payload }
    case LOAD_RECOMMENDATION:
      return { ...state, recommendation: payload }
    default:
      return state
  }
}

export default reducer
