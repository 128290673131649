import { initializeApp } from 'firebase/app'
import { getFirestore, collection, addDoc, Timestamp } from 'firebase/firestore'
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithRedirect,
  getRedirectResult,
  getAdditionalUserInfo,
} from 'firebase/auth'
import { firebaseConfig } from 'src/config/firebaseConfig'
import { success, error } from 'src/utils/toast'
import { sendCompleteRegistrationEvent } from 'src/utils/facebookPixel'
import { saveRegistrationLog } from './Auth'

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const auth = getAuth(app)

interface IInsert<V> {
  collectionName: string
  value: V
}

export const insert = <V>({
  collectionName,
  value,
}: IInsert<V>) => {
  const createdAt = Timestamp.fromDate(new Date())
  const collectionRef = collection(db, collectionName)
  return addDoc(collectionRef, {
    ...value,
    createdAt,
  })
}

export const signInWithGoogle = () => {
  const provider = new GoogleAuthProvider()
  return signInWithRedirect(auth, provider)
}

export const signInWithFacebook = () => {
  const provider = new FacebookAuthProvider()
  return signInWithRedirect(auth, provider)
}

export const getSignInResult = async (cbLogin, cbLoader) => {
  try {
    const result = await getRedirectResult(auth)
    if (result) {
      const { user }: any = result
      if (!!user) {
        const additionalUserInfo = getAdditionalUserInfo(result)
        const token = user.stsTokenManager.accessToken
        cbLogin(token)
        sendCompleteRegistrationEvent()
        if (additionalUserInfo.isNewUser) {
          await saveRegistrationLog({
            user: result.user.email,
            provider: additionalUserInfo.providerId,
          })
        }
        success('Bienvenido a FZ Academia')
      }
    }
    cbLoader(false)
  } catch (e) {
    if (e.code === 'auth/account-exists-with-different-credential') {
      error(`Ya existe un usuario con la dirección de correo electrónico ${e?.email || ''}`)
    } else if (Object.keys(cbLoader).length > 0) {
      error('Ocurrio un error inesperado')
      await saveRegistrationLog({
        user: e?.email,
        provider: e?.credential?.providerId,
        failedError: JSON.stringify(e),
        isFailed: true,
      })
    }
    cbLoader(false)
  }
}
