import { FunctionComponent } from 'react'
import { Backdrop, CircularProgress, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Typography } from 'src/components/atoms/Typography'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 999,
    color: ({ showBackground }: { showBackground: boolean }) => (!showBackground ? '#fff' : '#000'),
    backgroundColor: ({ showBackground }: { showBackground: boolean }) => showBackground && '#fff',
  },
}))

interface ILoaderProps {
  open: boolean
  message: string
  showBackground?: boolean
}

const Loader: FunctionComponent<ILoaderProps> = ({
  open,
  message,
  showBackground,
}) => {
  const classes = useStyles({ showBackground })

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <Grid container direction="column" spacing={3} alignItems="center">
        <Grid item>
          <Typography variant="h6">{message}</Typography>
        </Grid>
        <Grid item>
          <CircularProgress color="inherit" />
        </Grid>
      </Grid>
    </Backdrop>
  )
}

export default Loader
