import { OPEN_REGISTER_MODAL, PUBLIC_PRIVATE } from './types/constants'
import { GeneralState } from './types'

const INITIAL_VALUE: GeneralState = {
  isPublic: true,
  openRegisterModal: {
    isRegisterModalOpened: false,
    monthSelected: null,
    levelSlug: null,
    isPurchaseAll: false,
  },
}

const reducer = (state = INITIAL_VALUE, { type, payload }) => {
  switch (type) {
    case PUBLIC_PRIVATE:
      return { ...state, isPublic: payload }
    case OPEN_REGISTER_MODAL:
      return { ...state, openRegisterModal: payload }
    default:
      return state
  }
}

export default reducer
