import { CommonsState, LOAD_COUNTRIES, LOAD_INSTRUMENTS, CHANGE_LOADER, CHANGE_POINTS } from './types'

const INITIAL_STATE: CommonsState = {
  countries: [],
  instruments: [],
  loader: false,
  points: 0,
}

const reducer = (state = INITIAL_STATE, { payload, type }) => {
  switch (type) {
    case LOAD_COUNTRIES: return { ...state, countries: payload }
    case LOAD_INSTRUMENTS: return { ...state, instruments: payload }
    case CHANGE_LOADER: return { ...state, loader: payload }
    case CHANGE_POINTS: return { ...state, points: payload }
    default:
      return state
  }
}

export default reducer
