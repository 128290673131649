export const toSnakeCase = (word: string): string => word.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

export const formDataToSnakeCase = (obj) => {
  const formData = new FormData()
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of obj.entries()) {
    formData.set(toSnakeCase(key), value)
  }
  return formData
}

export const objectToSnakeCase = (obj: Object): any => {
  if (!obj) return null
  if (obj instanceof File || obj instanceof Blob) return obj
  if (obj instanceof FormData) return formDataToSnakeCase(obj)
  const arrayToCamelCase = (array: Array<any>) => array.map(objectToSnakeCase)

  if (Array.isArray(obj)) return arrayToCamelCase(obj)
  return Object.keys(obj).reduce((acc, key) => {
    const camelCaseKey = toSnakeCase(key)
    if (Array.isArray(obj[key])) {
      return { ...acc, [camelCaseKey]: arrayToCamelCase(obj[key]) }
    }
    if (typeof obj[key] === 'object') return { ...acc, [camelCaseKey]: objectToSnakeCase(obj[key]) }
    return { ...acc, [camelCaseKey]: obj[key] }
  }, {})
}
