export const toCamelCase = (word: string): string => word.replace(/([-_][a-z])/gi, ($1) => $1.toUpperCase().replace('-', '').replace('_', ''))

export const objectToCamelCase = (obj: Object) => {
  if (!obj) return null
  const arrayToCamelCase = (array: Array<any>) => array.map(objectToCamelCase)

  if (Array.isArray(obj)) return arrayToCamelCase(obj)

  return Object.keys(obj).reduce((acc, key) => {
    const camelCaseKey = toCamelCase(key)
    if (Array.isArray(obj[key])) {
      return { ...acc, [camelCaseKey]: arrayToCamelCase(obj[key]) }
    }
    if (typeof obj[key] === 'object') return { ...acc, [camelCaseKey]: objectToCamelCase(obj[key]) }
    return { ...acc, [camelCaseKey]: obj[key] }
  }, {})
}
