import * as CountryService from 'src/services/Country'
import * as InstrumentService from 'src/services/Instrument'
import { Action, CHANGE_LOADER, LOAD_COUNTRIES, LOAD_INSTRUMENTS, ChangeLoaderAction, CHANGE_POINTS } from './types'

export const loadCountries = (): Action => async (dispatch) => {
  const countries = await CountryService.getAllCountries()
  dispatch({ type: LOAD_COUNTRIES, payload: countries })
}

export const loadInstruments = (): Action => async (dispatch) => {
  const instruments = await InstrumentService.getAllInstruments()
  dispatch({ type: LOAD_INSTRUMENTS, payload: instruments })
}

export const changeLoader = (value: boolean): ChangeLoaderAction => ({ type: CHANGE_LOADER, payload: value })

export const changePoints = (points: number) => ({ type: CHANGE_POINTS, payload: points })
