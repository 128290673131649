import { NavigationState, CHANGE_MINI, CHANGE_OPEN, CHANGE_OPEN_MOBILE } from './types'

const INITIAL_STATE: NavigationState = {
  open: true,
  openMobile: false,
  mini: false,
}

const reducer = (state: NavigationState = INITIAL_STATE, { type, payload }): NavigationState => {
  switch (type) {
    case CHANGE_MINI:
      return { ...state, mini: payload }
    case CHANGE_OPEN:
      return { ...state, open: payload }
    case CHANGE_OPEN_MOBILE:
      return { ...state, openMobile: payload }
    default:
      return state
  }
}

export default reducer
