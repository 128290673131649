import Axios from 'src/services/Axios'
import formatUri from 'src/utils/formatUri'
import Uris from './Uris'

export interface IGenre {
  // eslint-disable-next-line no-use-before-define
  instrument: IInstrument
  name: 'Vallenato'
  slug: 'guitarra-vallenato'
}

export interface IInstrument {
  active: boolean
  image: string
  name: string
  slug: string
  genres?: IGenre[]
  classes?: number
}

export const getAllInstruments = async (): Promise<IInstrument[]> => {
  const uri = Uris.common.instruments.getAll
  return Axios.get(uri)
}

export const findInstrumentBySlug = async (slug: string): Promise<IInstrument> => {
  const uri = formatUri(Uris.common.instruments.find, slug)
  return Axios.get(uri)
}
