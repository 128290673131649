import { useSelector as useReduxSelector, TypedUseSelectorHook } from 'react-redux'
import { RootState } from 'src/store/reducers'
import { Action, Dispatch, Store } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { NextComponentType, NextPageContext } from 'next'
import { State } from './state'
import { RootActions } from './actions'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type ThunkAction<A extends Action> = (dispatch: ThunkDispatch<State, {}, RootActions>, getState: () => State, extraArgument: {}) => Promise<void> | void
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector

// @ts-ignore
interface StoreWithReduxThunk<S, A> extends Store<S, A> {
  // @ts-ignore
  dispatch: Dispatch<A | ThunkAction<A>>
}

interface NextPageContextRedux extends NextPageContext {
  store: StoreWithReduxThunk<State, RootActions>
}

export type NextPageRedux<P = {}, IP = P> = NextComponentType<NextPageContextRedux, IP, P>
