export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID

declare global {
  interface Window { fbq: any; }
}

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const fbEvent = (name, params = {}, options = {}) => {
  window.fbq('track', name, params, options)
}

export const pageView = () => {
  window.fbq('track', 'PageView')
}

export const addToCart = () => {
  window.fbq('track', 'AddToCart')
}

export const sendCompleteRegistrationEvent = () => {
  fbEvent(
    'CompleteRegistration',
    { currency: 'USD', value: 0 },
    {
      event_id: 'COMPLETE_REGISTRATION_BROWSER_PRODUCTION',
      action_source: 'website',
      event_source_url: window.location.href,
      client_user_agent: window.navigator.userAgent,
    },
  )
}

export const purchase = () => {
  fbEvent(
    'Purchase',
    { currency: 'USD', value: 0 },
    {
      event_id: 'COMPLETE_PURCHASE_BROWSER_PRODUCTION',
      action_source: 'website',
      event_source_url: window.location.href,
      client_user_agent: window.navigator.userAgent,
    },
  )
}
