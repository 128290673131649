import { ADD_ERROR, ErrorsState, REMOVE_ERROR } from 'src/store/modules/errors/types'

const INITIAL_STATE: ErrorsState = {
  data: [],
}

const reducer = (state = INITIAL_STATE, { payload, type }): ErrorsState => {
  switch (type) {
    case ADD_ERROR:
      return { ...state, data: [...state.data, { ...payload, error: { ...payload.error, id: state.data.length + 1 } }] }
    case REMOVE_ERROR: {
      const data = [...state.data].filter(({ error }) => error.id !== payload)
      return { ...state, data }
    }
    default:
      return state
  }
}

export default reducer
