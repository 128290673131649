import { AUTHENTICATE, CHANGE_USER, AuthState } from 'src/store/modules/auth/types'

const INITIAL_STATE: AuthState = {
  user: null,
  token: '',
}

const reducer = (state = INITIAL_STATE, { payload, type }) => {
  switch (type) {
    case AUTHENTICATE:
      return { ...state, token: payload }
    case CHANGE_USER:
      return { ...state, user: payload }
    default:
      return state
  }
}

export default reducer
