// packages
import 'react-toastify/dist/ReactToastify.min.css'
// import * as Sentry from '@sentry/node'
import CssBaseline from '@mui/material/CssBaseline'
import DateFnsUtils from '@date-io/date-fns'
import Head from 'next/head'
import { useRouter } from 'next/router'
import es from 'date-fns/locale/es'
import { AppProps } from 'next/app'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles'
import { ToastContainer } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { ReactElement, ReactNode, useEffect } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

// @scripts
import theme from 'src/theme'
import Loader from 'src/components/atoms/Loader'
import { error } from 'src/utils/toast'
import { isServer } from 'src/utils/isServer'
import { removeError } from 'src/store/modules/errors/actions'
import { useSelector } from 'src/store/types'
import { wrapperStore } from 'src/store'
import { changeLoader } from 'src/store/modules/commons/actions'
import { WhatsappButton } from 'src/components/Auth/SocialAuth/WhatsappButton'

// styles
import 'src/_app.scss'
import { FacebookPixel } from 'src/components/atoms/FacebookPixel'
import { NextPage } from 'next'
import { pageView } from '../utils/facebookPixel'
import initializeApp from '../core/index'

initializeApp()

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {
  }
}

/* if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
  })
} */

const queryClient = new QueryClient()

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const MyApp = (props: AppPropsWithLayout) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const { Component } = props
  const errors = useSelector(({ errors }) => errors.data)
  const openLoader = useSelector(({ commons }) => commons.loader)

  const handleOpenLoader = () => dispatch(changeLoader(true))
  const handleCloseLoader = () => dispatch(changeLoader(false))

  const handleRouteChangeComplete = () => {
    pageView()
    dispatch(changeLoader(false))
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    if (!isServer) {
      // @ts-ignore
      window.__localeId__ = 'es'
    }
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
  }, [])

  useEffect(() => {
    if (!!errors && errors.length > 0) {
      errors.forEach(({
        error: {
          title,
          message,
          id,
        },
      }) => {
        error(message, title, id)
        dispatch(removeError(id))
      })
    }
  }, [errors])

  useEffect(() => {
    pageView()
    router.events.on('routeChangeStart', handleOpenLoader)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    router.events.on('routeChangeError', handleCloseLoader)

    return () => {
      router.events.off('routeChangeStart', handleCloseLoader)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
      router.events.off('routeChangeError', handleCloseLoader)
    }
  }, [router.events])

  const getLayout = Component.getLayout || ((page) => page)

  return (
    <>
      <Head>
        <title>FZ academia</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <meta name="facebook-domain-verification" content="55mm900obgcx3bneoddfejwpyh1fgw" />
      </Head>
      <FacebookPixel />
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
        <StyledEngineProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <QueryClientProvider client={queryClient}>
              {
                  /* @ts-ignore */
                  getLayout(<Component {...props} />)
                }
              <Loader open={openLoader} message="Cargando" />
            </QueryClientProvider>
            <ToastContainer className="fz-toast" />
            <WhatsappButton />
          </ThemeProvider>
        </StyledEngineProvider>
      </MuiPickersUtilsProvider>
    </>
  )
}

export default wrapperStore.withRedux(MyApp)
