import {
  LevelState,
  LOAD_LEVELS,
  LOAD_LEVEL_TEMARY,
  LOAD_LEVEL_DETAIL,
  LOAD_LEVEL_PRICES,
  LOAD_SPECIAL_LEVELS,
} from './types'

const INITIAL_STATE: LevelState = {
  data: [],
  specials: [],
  prices: [],
  detail: null,
  temary: null,
}

const reducer = (state = INITIAL_STATE, { payload, type }) => {
  switch (type) {
    case LOAD_LEVELS:
      return { ...state, data: payload }
    case LOAD_SPECIAL_LEVELS:
      return { ...state, specials: payload }
    case LOAD_LEVEL_PRICES:
      return { ...state, prices: payload }
    case LOAD_LEVEL_DETAIL:
      return { ...state, detail: payload }
    case LOAD_LEVEL_TEMARY:
      return { ...state, temary: payload }
    default:
      return state
  }
}

export default reducer
