export default {
  courses: '/api/courses/',
  coursesPublic: '/api/courses_public/',
  levels: {
    get: '/api/levels/',
    getPublic: '/api/levels_public/',
    temary: '/api/levels/{0}/temary/',
    temaryPublic: '/api/levels_public/{0}/temary/',
    getPurchaseAll: '/api/levels/purchase-all/',
    getPurchaseAllPublic: '/api/levels_public/purchase-all/',
    exercises: {
      listFreeAndPrivate: '/api/levels/{0}/exercises',
      listFreeAndPrivatePublic: '/api/levels_public/{0}/exercises',
      getLastSeen: '/api/levels/{0}/exercises/last-seen/',
      geExerciseById: '/api/levels/{0}/exercises/{1}',
      geExerciseByIdPublic: '/api/levels_public/{0}/exercises/{1}',
    },
  },
  themes: {
    get: '/api/themes/',
    getPublic: '/api/themes_public/',
    getBySlug: '/api/themes/{0}/?level={1}',
    getBySlugPublic: '/api/themes_public/{0}/?level={1}',
    askForRate: '/api/themes/{0}/ask-for-rate/',
  },
  rates: {
    list: '/api/levels/{0}/rates/',
    listPublic: '/api/levels_public/{0}/rates/',
    stats: '/api/levels/{0}/rates/stats/',
    statsPublic: '/api/levels_public/{0}/rates/stats/',
  },
  videos: {
    products: '/api/courses/videos/',
    product: '/api/courses/videos/{0}/',
    myLibrary: '/api/courses/videos/purchased/',
    myLibraryDetail: '/api/courses/videos/{0}/purchased_detail/',
  },
  points: {
    get: '/api/points/',
    redeem: '/api/points/redeem/',
  },
  common: {
    instruments: {
      getAll: '/api/instruments/',
      find: '/api/instruments/{0}/',
    },
    countries: '/api/countries/',
  },
  shoppingCart: {
    getShoppingCart: '/api/sales/shopping-cart/get_shopping_cart/',
    createShoppingCart: '/api/sales/shopping-cart/',
    removeShoppingCart: '/api/sales/shopping-cart/{0}/',
  },
  notifications: {
    getAll: '/api/notifications/',
    readAll: '/api/notifications/mark-all-as-read/',
    readOne: '/api/notifications/{0}/mark-as-read/',
  },
  user: {
    whoami: '/api/users/whoami/',
  },
  sales: {
    historical: 'api/sales/historical',
  },
  admin: {
    blog: {
      posts: '/api/blog/posts/',
      post: '/api/blog/posts/{0}/',
      categories: {
        getAll: '/api/blog/categories/',
      },
    },
    students: {
      getAll: '/api/students/',
    },
  },
}
