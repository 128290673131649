import MockAdapter from 'axios-mock-adapter'
import Uris from '../services/Uris'
import points from '../config/mock-data'

import { instance as axiosInstance } from '../services/Axios'

const mockedServices = {
  mockServicePoints: (mockAdapter: MockAdapter) => {
    const uri = Uris.points.get
    mockAdapter.onGet(uri)
      .reply(200, { points })
  },
  mockServiceRedeemPoints: (mockAdapter: MockAdapter) => {
    const uri = Uris.points.redeem
    mockAdapter.onPost(uri, {
      redeem_type: 'tutorial',
      slug: 'guitarra-balada-desvanecer',
    })
      .reply(200, { points: { points: points.pointsPost } })
  },
}

export const initializeServiceMocker = () => {
  if (process.env.NEXT_PUBLIC_USE_MOCKS === 'true') {
    const mockAdapter = new MockAdapter(axiosInstance, {
      delayResponse: 20,
    })
    const serviceMocker = {
      replyWithMockData: () => {
        mockAdapter.reset()
        Object.keys(mockedServices)
          .forEach((key) => {
            mockedServices[key](mockAdapter)
          })
        mockAdapter.onAny()
          .passThrough()
      },
      replyWithNetWorkError: () => {
        mockAdapter.reset()
        mockAdapter.onAny()
          .networkError()
      },
    }
    serviceMocker.replyWithMockData()
    return serviceMocker
  }
  return null
}
