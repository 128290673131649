import thunkMiddleware from 'redux-thunk'
import { createStore, applyMiddleware } from 'redux'
import { createWrapper } from 'next-redux-wrapper'
import { ErrorHandler } from 'src/utils/errorHandler'
import middlewareFactory from './middlewares/errorHandler'
import reducers from './reducers'
import { RootActions } from './types/actions'
import { State } from './types/state'
import { addError } from './modules/errors/actions'

const errorHandlerMiddleware = middlewareFactory((e, dispatch) => {
  const errors = ErrorHandler(e)
  if (!!errors && errors.length > 0) {
    errors.forEach((error) => dispatch(addError(error)))
  }
})

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
    const { composeWithDevTools } = require('redux-devtools-extension')
    return composeWithDevTools(applyMiddleware(...middleware))
  }
  return applyMiddleware(...middleware)
}

const makeStore = () =>
  createStore<State, RootActions, {}, {}>(
    reducers,
    bindMiddleware([errorHandlerMiddleware, thunkMiddleware]),
  )

export const wrapperStore: any = createWrapper(makeStore)

export default makeStore
