import { Typography as MTypography } from '@mui/material'
import { ElementType, FC } from 'react'

interface Props {
  className?: string
  variant?:
    | 'inherit'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'caption'
    | 'button'
    | 'overline';
  color?:
    | 'initial'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'error'
  component?: ElementType
  [x: string]: any
}

export const Typography: FC<Props> = ({ children, ...props }) => (
  <MTypography {...props}>
    {children}
  </MTypography>
)
