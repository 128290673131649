import { LOAD_THEME, ThemeState } from './types'

const INITIAL_STATE: ThemeState = {
  theme: null,
}

const reducer = (state = INITIAL_STATE, { payload, type }) => {
  switch (type) {
    case LOAD_THEME:
      return { ...state, theme: payload }
    default:
      return state
  }
}

export default reducer
