import {
  ExercisesState,
  GET_LAST_SEEN_EXERCISE,
  GET_EXERCISE,
  CHANGE_PAGINATION,
  LIST_FREE_AND_PRIVATE_EXERCISES,
  CHANGE_LOADING,
  SEEN_EXERCISE,
} from './types'

const INITIAL_VALUE: ExercisesState = {
  exercise: null,
  list: [],
  pagination: {
    count: 0,
    next: null,
    previous: null,
    loading: false,
  },
  loading: false,
}

const reducer = (state = INITIAL_VALUE, { type, payload }) => {
  switch (type) {
    case LIST_FREE_AND_PRIVATE_EXERCISES:
      return { ...state, list: payload }
    case SEEN_EXERCISE:
      return { ...state, list: payload }
    case GET_EXERCISE:
      return { ...state, exercise: payload }
    case GET_LAST_SEEN_EXERCISE:
      return { ...state, exercise: payload }
    case CHANGE_PAGINATION:
      return { ...state, pagination: { ...state.pagination, ...payload } }
    case CHANGE_LOADING:
      return { ...state, loading: payload }
    default:
      return state
  }
}

export default reducer
