import jsCookie from 'js-cookie'
import cookie from 'cookie'
import { isServer } from 'src/utils/isServer'

export const setCookie = (key, value) => {
  if (!isServer) jsCookie.set(key, value, { expires: 1, path: '/' })
}

export const removeCookie = (key) => {
  if (!isServer) jsCookie.remove(key, { expires: 1 })
}

const getCookieFromBrowser = (key) => jsCookie.get(key)

const getCookieFromServer = (key, req) => {
  if (!req || !req.headers.cookie) return undefined
  const cookies = cookie.parse(req.headers.cookie)
  return cookies[key]
}

export const getCookie = (key, req?: any) => (!isServer ? getCookieFromBrowser(key) : getCookieFromServer(key, req))
