import { toast } from 'react-toastify'
import Alert from 'src/components/atoms/Alert'

export const success = (message: string, title?: string, toastId?: string) =>
  toast.success(<Alert type="success" message={message} title={title} />, { toastId })

export const error = (message: string, title?: string, toastId?: string) =>
  toast.error(<Alert type="error" message={message} title={title} />, { toastId })

export const warning = (message: string, title?: string, toastId?: string) =>
  toast.warning(<Alert type="warning" message={message} title={title} />, { toastId })

export const info = (message: string, title?: string, toastId?: string) =>
  toast.info(<Alert type="info" message={message} title={title} />, { toastId })
