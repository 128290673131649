import Axios from 'src/services/Axios'
import Uris from './Uris'

export interface ICountry {
  callingCodes: string[]
  codes: string[]
  currency: string
  flag: string
  id: number
  name: string
  nativeName: string
  numericCode: string
}

export const getAllCountries = async (): Promise<ICountry[]> => {
  const uri = Uris.common.countries
  return Axios.get(uri)
}
