import { AnyAction, combineReducers } from 'redux'
import { HYDRATE } from 'next-redux-wrapper'
import auth from 'src/store/modules/auth/reducer'
import shop from 'src/store/modules/shop/reducer'
import navigation from 'src/store/modules/navigation/reducer'
import blog from 'src/store/modules/blog/reducer'
import courses from 'src/store/modules/courses/reducer'
import errors from 'src/store/modules/errors/reducer'
import commons from 'src/store/modules/commons/reducer'
import levels from 'src/store/modules/levels/reducer'
import rates from 'src/store/modules/rates/reducer'
import exercises from 'src/store/modules/exercises/reducer'
import notifications from 'src/store/modules/notifications/reducer'
import general from 'src/store/modules/general/reducer'
import themes from 'src/store/modules/themes/reducer'
import { State } from './types/state'

const combinedReducer = combineReducers<State>({
  blog,
  levels,
  errors,
  navigation,
  courses,
  auth,
  shop,
  commons,
  rates,
  exercises,
  themes,
  notifications,
  general,
})

const rootReducer = (state: State, action: AnyAction) => {
  if (action.type === HYDRATE) {
    const nextState = { ...state, ...action.payload }
    if (state.commons) nextState.commons.loader = state.commons.loader
    return nextState
  }
  return combinedReducer(state, action)
}

export type RootState = ReturnType<typeof combinedReducer>

export default rootReducer
