export type ErrorHandlerType = (e, dispatch) => void

const middlewareFactory = (errorHandler: ErrorHandlerType) => (store) => (next) => async (action) => {
  try {
    return await next(action)
  } catch (e) {
    errorHandler(e, store.dispatch)
    throw e
  }
}

export default middlewareFactory
