import { Icon } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { FC } from 'react'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontWeight: 600,
    fontSize: 17,
  },
  message: {},
  content: {
    marginLeft: 15,
    width: '100%',
  },
}))

interface IAlertProps {
  type: string
  message: string
  title?: string
}

const icons = {
  success: 'check_circle',
  warning: 'warning',
  error: 'error',
  info: 'info',
}

const Alert: FC<IAlertProps> = ({ type, message, title }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Icon>{icons[type]}</Icon>
      <div className={classes.content}>
        <div className={classes.title}>{title}</div>
        <div className={classes.message}>{message}</div>
      </div>
    </div>
  )
}

export default Alert
