import { RatesState, GET_LEVEL_STATS, LOAD_RATES, ASK_FOR_RATE } from './types'

const INITIAL_STATE: RatesState = {
  askForRate: false,
  rates: [],
  stats: [],
}

const reducer = (state = INITIAL_STATE, { type, payload }): RatesState => {
  switch (type) {
    case ASK_FOR_RATE:
      return { ...state, askForRate: payload }
    case LOAD_RATES:
      return { ...state, rates: payload }
    case GET_LEVEL_STATS:
      return { ...state, stats: payload }
    default:
      return state
  }
}

export default reducer
