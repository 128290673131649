import { ShopState, LOAD_HAS_MORE, LOAD_PRODUCTS, LOAD_PRODUCT, LOAD_SHOPPING_CART } from 'src/store/modules/shop/types'

const INITIAL_STATE: ShopState = {
  products: [],
  product: null,
  hasMore: true,
  shoppingCart: null,
}

const reducer = (state = INITIAL_STATE, { type, payload }): ShopState => {
  switch (type) {
    case LOAD_PRODUCTS:
      return { ...state, products: payload }
    case LOAD_PRODUCT:
      return { ...state, product: payload }
    case LOAD_HAS_MORE:
      return { ...state, hasMore: payload }
    case LOAD_SHOPPING_CART:
      return { ...state, shoppingCart: payload }
    default:
      return state
  }
}

export default reducer
