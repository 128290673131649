import axios from 'axios'
import https from 'https'
import { isServer } from 'src/utils/isServer'
import { objectToCamelCase } from 'src/utils/toCamelCase'
import { objectToSnakeCase } from 'src/utils/toSnakeCase'
import { getToken } from './Auth'

export const instance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
})

const Axios = Object.freeze(instance)

Axios.interceptors.response.use((response) => objectToCamelCase(response.data))

Axios.interceptors.request.use((request) => {
  if (!isServer) {
    const token = getToken()
    if (token && token !== '') {
      request.headers.common.Authorization = `Bearer ${token}`
    }
  }
  const data = objectToSnakeCase(request.data)
  return { ...request, data }
})

export default Axios
