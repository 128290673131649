import {
  BlogState,
  CREATE_CATEGORY,
  EDIT_CATEGORY,
  LOAD_ADMIN_POSTS,
  LOAD_ADMIN_POSTS_COUNT,
  LOAD_CATEGORIES,
  DELETE_CATEGORY, FIND_POST_BY_SLUG, PUBLISH_POST,
} from './types'

const INITIAL_STATE: BlogState = {
  count: 0,
  adminPosts: [],
  categories: [],
  adminPost: null,
}

const reducer = (state = INITIAL_STATE, { payload, type }) => {
  switch (type) {
    case LOAD_ADMIN_POSTS:
      return { ...state, adminPosts: [...payload] }
    case LOAD_ADMIN_POSTS_COUNT:
      return { ...state, count: payload }
    case FIND_POST_BY_SLUG:
      return { ...state, adminPost: payload }
    case LOAD_CATEGORIES:
      return { ...state, categories: payload }
    case CREATE_CATEGORY:
      return { ...state, categories: [...state.categories, { ...payload }] }
    case EDIT_CATEGORY: {
      const categories = [...state.categories].map((category) => (category.id === payload.id ? payload : category))
      return { ...state, categories }
    }
    case PUBLISH_POST: {
      const adminPosts = [...state.adminPosts].map((post) => (post.id === payload.id ? payload : post))
      return { ...state, adminPosts }
    }
    case DELETE_CATEGORY: {
      const categories = [...state.categories].filter((category) => category.id !== payload)
      return { ...state, categories }
    }
    default:
      return state
  }
}

export default reducer
