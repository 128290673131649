import Axios from 'src/services/Axios'
import { getCookie, setCookie } from 'src/utils/cookie'
import { IInstrument } from './Instrument'
import { ICountry } from './Country'
import { insert } from './Firebase'
import Uris from './Uris'

interface IRegistrationLog {
  isFailed?: boolean
  failedError?: string
  provider: string
  user: string
}

export interface IUser {
  birthdate: Date
  country: ICountry
  email: string
  fullName: string
  hasInstrument: boolean
  instrument: IInstrument
  interestPlan: any
  isVerified: boolean
  joinedAt: Date
  lastLogin: Date
  phoneNumber: string
  picture: string
  role: string
}

export interface IAuthenticateRequest {
  email: string
  password: string
}

export interface IAuthenticateResponse {
  access: string
  refresh: string
  user: IUser
}

const uri = '/api'

const socialUri = '/api/students/social_signup/'

const verifyRole = (data: any): void => {
  if (!data || !data.user || !data.user.role) {
    // eslint-disable-next-line no-throw-literal
    throw {
      response: {
        status: 401,
        data: {
          errors: [{ message: 'Usuario no valido' }],
        },
      },
    }
  }
}

export const authenticate = async (credentials: IAuthenticateRequest): Promise<IAuthenticateResponse> => {
  const data: IAuthenticateResponse = await Axios.post(`${uri}/token/`, credentials)
  verifyRole(data)
  setCookie('token', data.access)
  setCookie('user', JSON.stringify(data.user))
  return data
}

export const socialLogin = async (tokenId: string): Promise<any> => {
  const data = await Axios.post(socialUri, { token_id: tokenId })
  verifyRole(data)
  return data
}

export const whoami = async (): Promise<IUser> => {
  const data: IUser = await Axios.get(Uris.user.whoami)
  setCookie('user', JSON.stringify(data))
  return data
}

export const getToken = (req?: any) => getCookie('token', req)

export const getUser = (req?: any) => {
  let user = getCookie('user', req)
  if (!!user && user !== '') user = JSON.parse(user)
  return user
}

export const saveRegistrationLog = ({ provider, user, isFailed = false, failedError = '' }: IRegistrationLog) =>
  insert<IRegistrationLog>({
    collectionName: 'registration-logs',
    value: { provider, user, isFailed, failedError },
  })
