import { createTheme, adaptV4Theme } from '@mui/material/styles'

const theme = createTheme(adaptV4Theme({
  palette: {
    primary: {
      main: '#FFA304',
      contrastText: '#FFFFFF',
      light: '#858585',
    },
    secondary: {
      main: '#2D2D2D',
      light: '#eeeeee',
    },
    error: {
      main: '#e22434',
    },
    success: {
      main: '#4caf50',
    },
    background: {
      default: '#fff',
    },
    grey: {
      500: '#000',
    },
  },
}))

export default theme
