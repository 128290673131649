import Img from 'next/image'
import { FC, useState } from 'react'

interface Props {
  alt?: string
  className?: string
  fallbackSrc?: string
  height?: string | number
  src: string
  width?: string | number
  layout?: 'responsive' | 'fixed' | 'fill' | 'intrinsic'
  style?: object
}

export const Image: FC<Props> = ({
  alt,
  className,
  fallbackSrc,
  height = '100%',
  src,
  width = '100%',
  layout = 'responsive',
  style = {},
}) => {
  const [url, setUrl] = useState(src)
  const [errored, setErrored] = useState(false)

  const handleError = () => {
    if (!errored) {
      setErrored(true)
      if (fallbackSrc) setUrl(fallbackSrc)
    }
  }

  return (
    <Img
      alt={alt}
      className={className}
      height={height}
      width={width}
      src={url}
      layout={layout}
      onError={handleError}
      style={style}
    />
  )
}
