// @packages
import { Image } from 'src/components/atoms/Image'
import { makeStyles } from '@mui/styles'

// @scripts
import { whatsappUrl } from 'src/config/constants/landing'

// @store
import { Theme } from '@mui/material'
import { useSelector } from '../../../store/types'

const useMakeStyles = makeStyles((theme: Theme) => ({
  whatsappIcon: {
    display: ({ isPublic }: { isPublic: boolean }) => (isPublic ? 'block' : 'none'),
    position: 'fixed',
    bottom: '25px',
    left: '25px',
    background: 'transparent',
    filter: 'drop-shadow(5px 5px 2px rgba(0,0,0,.4))',
    width: 'auto',
    height: '50px',
    cursor: 'pointer',
    zIndex: theme.zIndex.drawer + 1,
    [theme.breakpoints.down('sm')]: {
      display: 'block !important',
      left: '10px',
    },
    '&:hover': {
      transition: '.3s',
      transform: 'scale(1.02) !important',
    },
    '&:active': {
      transition: '.3s',
      transform: 'scale(.9) !important',
    },

  },
}))

export const WhatsappButton = () => {
  const isPublic = useSelector(({ general }) => general.isPublic)
  const classes = useMakeStyles({ isPublic })

  return (
    <a href={whatsappUrl} target="_blank" className={classes.whatsappIcon} rel="noreferrer">
      <Image
        height={50}
        width={50}
        src="/images/whatsapp-icon.png"
        alt="Whatsapp icon"
        layout="fixed"
      />
    </a>
  )
}
